<template>
  <div
    class="pop-up-close"
    @click="$pop.down()"
  >
    <svg
      viewBox="0 0 12 12" fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L6 6M6 6L11 11M6 6L1 11M6 6L11 1" stroke="#787C8D" stroke-width="2" stroke-linecap="round"/>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.pop-up-close {
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #9CA3B8;
  cursor: pointer;
  transition: .2s;

  &:hover {
    border: 1px solid white;

    svg path {
      stroke: white;
    }
  }

  &.black {
    &:hover {
      border: 1px solid rgb(var(--primary-color));

      svg path {
        stroke: rgb(var(--primary-color));
      }
    }
  }

  svg {
    margin: 0 auto;
    height: 9px;
    width: 9px;

    path {
      transition: .2s;
    }
  }
}
</style>
