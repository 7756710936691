<template>
  <div
    class="copy-button"
    :class="{
      copied: copied
    }"
    @click="copy()"
  >
    <div class="copy-button__text">
      {{ text }}
    </div>

    <div class="copy-button__icon">
      <svg
        v-if="!copied"
        viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6978_18466)">
          <path d="M7.4622 13.7195L5.34118 15.8405C4.46185 16.7198 3.03783 16.7198 2.15924 15.8407C1.28047 14.9619 1.28047 13.5377 2.15906 12.6591L6.40185 8.41635C7.28045 7.53772 8.7046 7.53772 9.58319 8.41635C9.87608 8.70924 10.351 8.70924 10.6439 8.41635C10.9368 8.12346 10.9368 7.64857 10.6439 7.35568C9.17946 5.89127 6.80559 5.89127 5.34118 7.35568L1.09843 11.5984C-0.36598 13.0628 -0.36598 15.4367 1.09843 16.9011C2.56266 18.3663 4.9367 18.3663 6.40189 16.9011L8.52291 14.7801C8.8158 14.4872 8.8158 14.0123 8.52291 13.7194C8.23002 13.4265 7.75509 13.4266 7.4622 13.7195Z" fill="#2F57E9"/>
          <path d="M16.9014 1.09829C15.437 -0.366119 13.0624 -0.366119 11.5979 1.09829L9.05318 3.64305C8.76029 3.93594 8.76029 4.41084 9.05318 4.70372C9.34607 4.99661 9.82096 4.99661 10.1138 4.70372L12.6586 2.15896C13.5372 1.28033 14.9621 1.28033 15.8407 2.15896C16.7193 3.03755 16.7193 4.46171 15.8407 5.3403L11.1742 10.0069C10.2956 10.8855 8.87146 10.8855 7.99286 10.0069C7.69997 9.71397 7.22508 9.71397 6.93219 10.0069C6.6393 10.2998 6.6393 10.7746 6.93219 11.0675C8.3966 12.5319 10.7705 12.5319 12.2349 11.0675L16.9014 6.40101C18.3658 4.9366 18.3658 2.5627 16.9014 1.09829Z" fill="#2F57E9"/>
        </g>
        <defs>
          <clipPath id="clip0_6978_18466">
            <rect width="18" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>

      <svg
        v-else
        class="copy"
        viewBox="0 0 20 15" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 6.28571L7.81818 12L18 2" stroke="#D3FF53" stroke-width="3" stroke-linecap="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Copy'
    },
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      copied: false
    }
  },
  methods: {
    copy () {
      this.copied = true

      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = this.value

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.copied = false
      }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-button {
  height: 46px;
  width: 100%;
  padding: 5px 5px 5px 51px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 36px;
  gap: 15px;
  background: rgba(47, 87, 233, 0.2);
  backdrop-filter: blur(30px);
  border-radius: 30px;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background: #577FFF;

    .copy-button__text {
      color: #FFFFFF;
    }
  }

  &.copied {
    cursor: default;

    &:hover {
      background: rgba(47, 87, 233, .2);

      .copy-button__text {
        color: #2F57E9;
      }

      // .copy-button__icon {
      //   background: #2F57E9;

      //   svg path {
      //     fill: #FFFFFF;
      //   }
      // }
    }

    .copy-button__text {
      color: #2F57E9;
    }

    .copy-button__icon {
      background: rgba(32, 37, 57, 1) !important;
    }
  }

  &.h24 {
    height: 24px !important;
    width: 24px !important;

    .copy-button__icon {
      height: 24px !important;
      width: 24px !important;

      svg {
        height: 12px;
      }
    }
  }

  &.simple {
    height: auto !important;
    width: 36px;
    padding: 0 !important;
    background: transparent;

    &:hover {
      background: transparent;
    }

    .copy-button__text {
      display: none;
    }
  }

  .copy-button__text {
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #202539;
    font-family: SemiBold;
    transition: .2s;
  }

  .copy-button__icon {
    height: 36px;
    width: 36px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 30px;
    transition: .2s;

    svg {
      max-height: 18px;
      max-width: 18px;
      margin: 0 auto;

      &.copy {
        max-height: 14px !important;
        max-width: 14px !important;
      }

      path {
        transition: .2s;
      }
    }
  }
}
</style>
