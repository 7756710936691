<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton class="black" />
    </div>

    <div class="container__content">
      <div class="container__content__title">
        {{ $t('components.ui.pages.pop-up.invite.pay-title') }}
      </div>

      <p class="container__content__text invite-pop-up">
        {{ $t('components.ui.pages.pop-up.invite.pay-text') }}
      </p>

      <QrCode
        :value="_parseReferralLink"
      />

      <CopyButton
        class="container__content__button"
        :text="$t('components.ui.pages.pop-up.invite.pay-button')"
        :value="_parseReferralLink"
      />

      <p class="container__content__id">
        {{ $t('components.ui.pages.pop-up.invite.pay-id') }} #{{ _getUserId }}
      </p>
    </div>

    <!-- <div
      v-else
      class="container__content"
    >
      <div class="container__content__title">
        {{ $t('components.ui.pages.pop-up.invite.no-pay-title') }}
      </div>

      <p
        class="container__content__text invite-pop-up"
        v-html="$t('components.ui.pages.pop-up.invite.no-pay-text')"
      />

      <img
        :src="require(`@/static/images/pop-up/invite-pop-up-no-pay-${$i18n.locale}.png`)"
      >

      <BlueButton
        tag="RouterLink"
        :to="{ name: 'Store' }"
        :text="$t('components.ui.pages.pop-up.invite.no-pay-button')"
      />
    </div> -->
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import QrCode from '@/ui/QrCode.vue'
import CopyButton from '@/ui/buttons/Copy.vue'
// import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    QrCode,
    CopyButton
    // BlueButton
  },
  data () {
    return {
      isCopy: false
    }
  },
  computed: {
    _getUserTurnover () {
      return this.$store.state.user.data?.turnover_user || 0
    },
    _getUserId () {
      return this.$store.state.user.data?.id
    },
    _parseReferralLink () {
      return `${window.location.protocol}//${window.location.hostname}/${this.$i18n.locale}/auth/sign-up?referral=${this._getUserId}`
    }
  },
  methods: {
    copy () {
      this.isCopy = true
      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = this._parseReferralLink

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.isCopy = false
      }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  max-width: 280px;
  background: #FFFFFF;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    padding: 8px 8px 0 8px;
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .container__content__title {
      font-size: 18px;
      line-height: 120%;
      text-align: center;
      color: #202539;
      font-family: SemiBold;
    }

    img {
      max-width: 200px;
      margin: 0 auto;
    }

    .container__content__id {
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      color: #787C8D;
      font-family: Medium;
    }
  }
}
</style>

<style lang="scss">
p.container__content__text.invite-pop-up {
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #787C8D;
  font-family: Medium;

  span {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &.container__content__text__blue {
      color: #2F57E9;
    }

    &.container__content__text__green {
      color: #00B207;
    }
  }
}
</style>
